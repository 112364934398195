

.p-input-icon-left,
.p-input-icon-right {
    position: relative;
    display: inline-block;
}

.number {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    font-style: normal;
    padding-left: 4px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #6c757d !important;
    position: absolute;
    top: 8px;
    left: 0px;
}
