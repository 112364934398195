$light-color: hsl(233, 57%, 97%);
$gray-color: #424656;
$almost-black: #262935;
$light-gray: #f5fcff;
$white-color: white;

$eprawnik-lexy-light-color-03-opacity: rgba(240, 213, 154, 0.3);
$eprawnik-lexy-chat-background-color: rgb(253, 250, 242);
$eprawnik-lexy-color: #eeb934;
$eprawnik-lexy-light-color: #f0d59a;

$eprawnik-lexdocs-color: #5c74ac;
$eprawnik-lexdocs-light-color: #93a6d6;
$eprawnik-lexdocs-light-color-03-opacity: rgba(147, 166, 214, 0.3);
$eprawnik-lexdocs-chat-background-color: rgb(247, 249, 252);
$eprawnik-lexdocs-color-dark: #313745;

#close-button {
    margin-right: 10px;
    margin-top: -5px;
}
.chat-btn {
    position: absolute;
    right: 14px;
    bottom: 30px;
    cursor: pointer;
}

.chat-btn .close {
    display: none;
}

.chat-btn i {
    transition: all 0.9s ease;
}
.lexy.chat-btn {
    background-color: $eprawnik-lexy-color;
}
.lexdocs.chat-btn {
    background-color: $eprawnik-lexdocs-color;
}

#check:checked ~ .chat-btn i {
    display: block;
    pointer-events: auto;
    transform: rotate(180deg);
}

#check:checked ~ .chat-btn .comment {
    display: none;
}

.chat-btn i {
    font-size: 22px;
    color: #fff !important;
}

.chat-btn {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #fff;
    font-size: 22px;
    border: none;
    z-index: 1000;
}

.wrapper {
    position: absolute;
    right: 20px;
    bottom: 110px;
    width: 400px;
    background-color: #fff;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s;
    z-index: 1;
}

.wrapperChat {
    position: absolute;
    /* right: 20px; */
    /* bottom: 110px; */
    border-radius: 5px;
    opacity: 1;
    transition: all 0.4s;
    z-index: 1;
    border: 1px solid $light-gray;
}
.lexy.wrapperChat,
.lexy.wrapperVideoChat {
    background-color: $eprawnik-lexy-chat-background-color;
    border: 1px solid $eprawnik-lexy-light-color;
    box-shadow: 0px 4px 4px $eprawnik-lexy-color;
}
.lexdocs.wrapperChat,
.lexdocs.wrapperVideoChat {
    background-color: $eprawnik-lexdocs-chat-background-color;
    box-shadow: 0px 4px 4px $eprawnik-lexdocs-color-dark;
    border-radius: 0px;
}

.wrapperVideoChat {
    position: absolute;
    /* right: 20px; */
    /* bottom: 110px; */
    background-color: #fffef5;
    border-radius: 5px;
    opacity: 1;
    transition: all 0.4s;
    z-index: 1;
}
.videoChatHeader,
.chat-header {
    .buttons-videoHeader {
        padding: 2px 0px 2px 0px;
    }
    a {
        font-size: 20px;
        padding: 0px 15px;
    }
}
@media (max-width: 700px) {
    .streamcomponent {
        width: 300px;
        height: 218px;
    }

    .desc-chat-video-header {
        display: none;
    }
    .largeVideo {
        width: 300px;
        height: 218;
        margin-left: 2px;
        margin-right: 2px;
    }
    .videoChatHeader {
        min-width: 300px;
    }
    .smallVideo {
        width: 100px;
        height: 75px;
        position: absolute;
        right: 20px;
        bottom: 80px;
    }
    .wrapperChat {
        width: 350px;
    }
}
@media (min-width: 700px) {
    .streamcomponent {
        width: 660px;
        height: 480px;
    }
    .largeVideo {
        width: 640px;
        height: 480px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .videoChatHeader {
        min-width: 660px;
    }

    .smallVideo {
        width: 160px;
        height: 120px;
        position: absolute;
        right: 20px;
        bottom: 80px;
    }
    .wrapperChat {
        width: 400px;
    }
}

video {
    width: 100%;
    height: auto;
    float: left;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.noVideo {
    background-color: #2e3541;
}

.desc-chat-video-header {
    font-size: 14px;
}
.videoBar {
    text-align: center;
    .back-to-template {
        display: flex;
        justify-content: center;
        cursor: pointer;
    }
    a {
        padding: 20px;
    }
}
.lexdocs .videoBar {
    .icon_text {
        color: $eprawnik-lexdocs-color;
    }
    .icon_text > .mdi-phone-off {
        color: #db1818;
    }
}

#check:checked ~ .wrapper {
    opacity: 1;
    visibility: visible;
}

.chat-header {
    /* padding: 1px; */
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 2px;
    color: #fff;
}
.lexy {
    .chat-header {
        background-color: $eprawnik-lexy-color;
    }
}
.lexdocs {
    .chat-header {
        background-color: $eprawnik-lexdocs-color-dark;
        border-radius: 0px;
    }
}

.chat-form {
    padding: 15px;
}

.chat-form input {
    margin-bottom: 25px;
    padding-bottom: 25px;
    width: 100px;
}
.chat-form button {
    margin-bottom: 25px;
    padding-bottom: 25px;
    width: 100px;
}

.chat-form textarea {
    resize: none;
}

.form-control:focus,
.btn:focus {
    box-shadow: none;
}

.btn,
.btn:focus,
.btn:hover {
    background-color: #8dcdff;
    border: #8dcdff;
}

#check {
    display: none !important;
}

.chat-body h6 {
    font-size: 20px;
    margin: 0 0 20px;
}
.chat-body .answer.left {
    padding: 0 0 0 20px;
    text-align: left;
    float: left;
}
.chat-body .answer {
    position: relative;
    max-width: 600px;
    overflow: hidden;
    clear: both;
}
.chat-body .answer.left .avatar {
    left: 0;
}
.chat-body .answer .avatar {
    bottom: 36px;
}
.chat .avatar {
    width: 40px;
    height: 40px;
    position: absolute;
}
.chat .avatar img {
    display: block;
    border-radius: 20px;
    height: 100%;
}
.chat-body .answer .name {
    font-size: 14px;
    line-height: 25px;
}
.chat-body .answer.left .avatar .status {
    right: 4px;
}
.chat-body .answer .avatar .status {
    bottom: 0;
}
.chat-body .answer.left .text {
    background: #ebebeb;
    color: #2e3541;
    border-radius: 8px 8px 8px 0;
}
.chat-body .answer .text {
    padding: 12px;
    font-size: 16px;
    line-height: 26px;
    position: relative;
}
.chat-body .answer.left .text:before {
    left: -30px;
    border-right-color: #ebebeb;
    border-right-width: 12px;
}
.chat-body .answer .text:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    border: 18px solid transparent;
    border-bottom-width: 0;
}
.chat-body .answer.left .time {
    padding-left: 12px;
    color: #2e3541;
}
.chat-body .answer .time {
    font-size: 12px;
    line-height: 25px;
    position: relative;
    padding-bottom: 1px;
}
/*RIGHT*/
.chat-body .answer.right {
    padding: 0 20px 0 0;
    text-align: right;
    float: right;
}

.chat-body .answer.right .avatar {
    right: 0;
}
.chat-body .answer.right .avatar .status {
    left: 4px;
}
.chat-body .answer.right .text {
    background: $gray-color;
    color: #ffffff;
    border-radius: 8px 8px 0 8px;
}
.chat-body .answer.right .text:before {
    right: -30px;
    border-left-width: 12px;
}
.chat-body .answer.right .time {
    padding-right: 12px;
    color: $almost-black;
}
.lexy.chat-body .answer.right .text:before {
    border-left-color: $eprawnik-lexy-color;
}
.lexdocs.chat-body .answer.right .text:before {
    border-left-color: $eprawnik-lexdocs-color;
}

/**************ADD FORM ***************/
.chat-body .answer-add {
    clear: both;
    position: relative;
    margin: 20px -20px -20px;
    padding: 20px;
    background: #46be8a;
}
.chat-body .answer-add input {
    border: none;
    background: none;
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    color: #ffffff;
}
.chat input {
    -webkit-appearance: none;
    border-radius: 0;
}
.chat-body .answer-add .answer-btn-1 {
    background: url('http://91.234.35.26/iwiki-admin/v1.0.0/admin/img/icon-40.png') 50% 50% no-repeat;
    right: 56px;
}
.chat-body .answer-add .answer-btn {
    display: block;
    cursor: pointer;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 50%;
    margin-top: -18px;
}
.chat-body .answer-add .answer-btn-2 {
    background: url('http://91.234.35.26/iwiki-admin/v1.0.0/admin/img/icon-41.png') 50% 50% no-repeat;
    right: 20px;
}
.chat input::-webkit-input-placeholder {
    color: #fff;
}

.chat input:-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
}

.chat input::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
}

.chat input:-ms-input-placeholder {
    color: #fff;
}
.chat input {
    -webkit-appearance: none;
    border-radius: 0;
}

.form-elegant {
    position: relative;
    height: 450px;
    overflow-y: scroll;
}
.scrollbar-light-blue::-webkit-scrollbar-track {
    border-radius: 10px;
}
.lexy .scrollbar-light-blue::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $eprawnik-lexy-color;
    background-color: #f5f5f5;
    border-radius: 10px;
}
.lexdocs .scrollbar-light-blue::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $eprawnik-lexdocs-color;
    background-color: #f5f5f5;
    border-radius: none !important;
}

.scrollbar-light-blue::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

.scrollbar-light-blue::-webkit-scrollbar-thumb {
    border-radius: 10px;
}
.lexy .scrollbar-light-blue::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 15px $eprawnik-lexy-color;
}
.lexdocs .scrollbar-light-blue::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 15px $eprawnik-lexdocs-color;
}
.lexy.scrollbar-light-blue::-webkit-scrollbar-thumb {
    background-color: $eprawnik-lexy-light-color-03-opacity;
}
.lexdocs.scrollbar-light-blue::-webkit-scrollbar-thumb {
    background-color: $eprawnik-lexdocs-light-color-03-opacity;
}

.lexy .scrollbar-light-blue {
    scrollbar-color: $eprawnik-lexy-light-color #f5f5f5;
    //for FF
    scrollbar-width: thin;
}
.lexdocs .scrollbar-light-blue {
    scrollbar-color: $eprawnik-lexdocs-light-color #f5f5f5;
    //for FF
    scrollbar-width: thin;
}

.position-top {
    z-index: 99999;
    position: absolute;
}
.react-draggable {
    z-index: 1001;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mr-auto {
    margin-right: auto !important;
}

.float-right {
    float: right;
}

.white {
    color: white;
}
.bg-warning-chat {
    background-color: $gray-color;
}
