.numberCircle-xl {
    border-radius: 100%;
    width: 16px;
    height: 16px;
    position: absolute;
    padding: 2px;
    margin-top: -2px;
    margin-left: -2px;
    color: white;
    background: #db1818;
    text-align: center;

    font: 10px Arial, sans-serif;
}

.numberCircle-xs {
    border-radius: 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    padding: 1px;
    margin-left: -8px;
    margin-top: -5px;
    color: white;
    background: #db1818;
    text-align: center;
    font: 10px Arial, sans-serif;
}
.reminder-overlay {
    font-size: 12px;
    font-weight: 600;
}
.reminder-note {
    .list-group {
        padding-left: 3px;
        padding-right: 3px;
        a span {
            font-size: 12px;
        }
    }
}
.p-overlaypanel {
    background: #ffffff;
    color: #495057;
    border: 1px solid #495057;
    box-shadow: none;
}
.p-overlaypanel.p-component.reminder-overlay.p-overlaypanel-enter-done {
    --overlayArrowLeft: none !important;
}
